import React, { useEffect, useState } from "react";
import { Button, ConfirmationModal, TextButton } from "UI";
import { apiGenerator, classnames } from "Utils";
import SecurePaymentModal from "Utils/Components/Payment/SecurePaymentModal/SecurePaymentModal.component";
import { useMutation } from "@tanstack/react-query";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import { useDispatch, useSelector } from "react-redux";
import { capitalize } from "lodash";
import { getVendorAccountAPI } from "Actions/AppStreaming.actions";
import VendorBasic from "Assets/images/appStreaming/standard-plan.png";
import VendorPro from "Assets/images/appStreaming/pro-plan.png";
import VendorEnterprise from "Assets/images/appStreaming/enterprise-plan.png";
import routes from "Constants/Route.constants";
import moment from "moment";
import DashboardSubScreenLayout from "Utils/Components/Layouts/DashboardSubScreenLayout/DashboardSubScreenLayout.component";
import ProfilePicture from "UI/ProfilePicture/ProfilePicture.component";
import useMatchTimeZone from "Utils/Hooks/useMatchTimeZone";
import TimezoneModal from "Components/Dashboard/Files/Components/TimezoneModal/TimezoneModal.component";
import VendorAdmins from "./VendorAdmins/VendorAdmins.component";
import VendorPlansTable from "./PlansTable/PlansTable.component";
import PaymentErrorModal from "../Modals/PaymentErrorModal/PaymentErrorModal.component";

import "./AppStreamingSettings.styles.scss";

const DOWNGRADE_TYPES = {
  none: "none",
  basic: "basic",
  pro: "pro",
};

const AppStreamingSettings = ({ translate, history }) => {
  const [showFullPlanComparison, setShowFullPlanComparison] = useState(false);
  const [showUpgradeConfirmationModal, setShowUpgradeConfirmationModal] = useState(false);
  const [showDowngradeConfirmationModal, setShowDowngradeConfirmationModal] = useState(false);
  const [showTimezoneModal, setShowTimezoneModal] = useState(false);
  const [downgradeType, setDowngradeType] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVendorAccountAPI());
  }, []);

  const {
    name: vendorName,
    total_vendor_admin_count: totalVendorAdminCount,
    vendor_admin_limit: vendorAdminLimit,
    "vendor_admin_limit_reached?": vendorAdminLimitReached,
    pro,
    enterprise,
    subscription,
  } = useSelector((state) => state.appStreaming.getVendorAccountCTX?.data?.attributes) || {};
  const {
    email: vendorEmail,
    profile_picture_url: profilePictureUrl,
    time_zone: userTimeZone,
  } = useSelector((state) => state?.account?.account?.attributes) || {};
  const { account } = useSelector((state) => state.account) || {};
  const { downgrade_to: downgradeTo, status: subscriptionStatus } = subscription?.attributes || {};
  const willBeDowngraded = downgradeTo && downgradeTo !== "none";
  const vendorAdminsWillBeDeleted = () => {
    if (downgradeType === "basic" && totalVendorAdminCount > 1) return true;
    if (downgradeType === "pro" && totalVendorAdminCount > 5) return true;
    return false;
  };
  const timezoneLabel = useMatchTimeZone(userTimeZone);
  const basic = !pro && !enterprise;

  const createSubscriptionMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(APP_STREAMING_API_ENDPOINTS.VENDOR_SUBSCRIPTIONS, data);
    },
    onSuccess: (data) => {
      setShowUpgradeConfirmationModal(false);
      setClientSecret(data.data.client_secret);
    },
    onError: () => {
      setShowUpgradeConfirmationModal(false);
    },
  });

  const completeSubscriptionMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(APP_STREAMING_API_ENDPOINTS.VENDOR_SUBSCRIPTIONS_COMPLETE, data);
    },
    onSuccess: () => {
      setClientSecret(null);
      dispatch(getVendorAccountAPI());
    },
  });

  const downgradeSubscriptionMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(APP_STREAMING_API_ENDPOINTS.VENDOR_SUBSCRIPTIONS_DOWNGRADE, data);
    },
    onSuccess: () => {
      setShowDowngradeConfirmationModal(false);
      setShowFullPlanComparison(false);
      dispatch(getVendorAccountAPI());
    },
  });

  const calculateUpgradePriceMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(APP_STREAMING_API_ENDPOINTS.VENDOR_SUBSCRIPTIONS_CALCULATE, data);
    },

    onSuccess: () => {
      setShowUpgradeConfirmationModal(true);
    },
  });

  const basicPlanActionText = () => {
    if (downgradeTo === DOWNGRADE_TYPES.basic) return "Cancel Downgrade";
    if (!basic) return "Downgrade";
    return "Active";
  };

  const basicPlanAction = () => {
    if (subscriptionStatus === "pending_payment") return false;
    if (subscription && !willBeDowngraded)
      return () => {
        setDowngradeType(DOWNGRADE_TYPES.basic);
        setShowDowngradeConfirmationModal(true);
      };
    if (downgradeTo === DOWNGRADE_TYPES.basic) {
      return () => {
        downgradeSubscriptionMutation.mutate({
          downgrade_to: "none",
        });
      };
    }

    return false;
  };

  const proPlanActionText = () => {
    if (downgradeTo === DOWNGRADE_TYPES.pro) return "Cancel Downgrade";
    if (enterprise) return "Downgrade";
    if (pro) return "Active";
    return "Upgrade";
  };

  const proPlanAction = () => {
    if (subscriptionStatus === "pending_payment") return false;
    if (enterprise && !willBeDowngraded)
      return () => {
        setShowDowngradeConfirmationModal(true);
        setDowngradeType("pro");
      };

    if (downgradeTo === DOWNGRADE_TYPES.pro) {
      return () => {
        downgradeSubscriptionMutation.mutate({
          downgrade_to: "none",
        });
      };
    }

    if (!subscription) return () => calculateUpgradePriceMutation.mutate({ subscription_type: "pro" });

    return false;
  };

  const enterprisePlanActionText = () => {
    if (enterprise) return "Active";
    return "Upgrade";
  };

  const enterprisePlanAction = () => {
    if (subscriptionStatus === "pending_payment") return false;
    if (!subscription || pro) return () => calculateUpgradePriceMutation.mutate({ subscription_type: "enterprise" });
    return false;
  };

  const basicPlanButton = () => {
    return (
      <Button
        aquaDarkOutline={basic}
        gray4Outline={!basic}
        className={classnames("vendor-type-card-button")}
        onClick={basicPlanAction()}
        text={basicPlanActionText()}
      />
    );
  };

  const proPlanButton = () => {
    return (
      <Button
        aquaDarkOutline={pro}
        gray4Outline={enterprise}
        aqua={!pro && !enterprise}
        className="vendor-type-card-button"
        text={proPlanActionText()}
        onClick={proPlanAction()}
      />
    );
  };

  const enterprisePlanButton = () => {
    return (
      <Button
        aquaDarkOutline={enterprise}
        aqua={!enterprise}
        className="vendor-type-card-button"
        text={enterprisePlanActionText()}
        onClick={enterprisePlanAction()}
      />
    );
  };

  const basicDescriptionAltText = () => {
    if (downgradeTo === DOWNGRADE_TYPES.basic)
      return `Will be updated on ${moment(subscription?.attributes?.end_at).format("DD.MM.YYYY")}`;
    return " ";
  };

  const proDescriptionAltText = () => {
    if (downgradeTo === DOWNGRADE_TYPES.pro)
      return `Will be updated on ${moment(subscription?.attributes?.end_at).format("DD.MM.YYYY")}`;
    if (pro && downgradeTo === DOWNGRADE_TYPES.none)
      return `Will be renewed on ${moment(subscription?.attributes?.end_at).format("DD.MM.YYYY")}`;
    return " ";
  };

  const enterpriseDescriptionAltText = () => {
    if (!enterprise) return " ";
    if (downgradeTo === DOWNGRADE_TYPES.none)
      return `Will be renewed on ${moment(subscription?.attributes?.end_at).format("DD.MM.YYYY")}`;
    return " ";
  };

  return (
    <DashboardSubScreenLayout
      appStreaming
      headerTitle={showFullPlanComparison ? "Streams Plans" : "Settings"}
      showCloseIcon={showFullPlanComparison}
      onClose={() => setShowFullPlanComparison(false)}
    >
      <div className="app-streaming-settings">
        {showFullPlanComparison ? (
          <VendorPlansTable
            basicPlanButton={basicPlanButton}
            proPlanButton={proPlanButton}
            enterprisePlanButton={enterprisePlanButton}
          />
        ) : (
          <>
            <div className="account-row">
              <div className="settings-container">
                <p className="header">Profile</p>
                <div className="company-container">
                  <ProfilePicture account={account} translate={translate} />
                  <div className="company-info">
                    <div className="info-container">
                      {vendorName && <div className="info-label">Company</div>}
                      <div className="info-value">{vendorName}</div>
                    </div>
                    <div className="info-container">
                      <div className="info-label">Email</div>
                      <div className="info-value">{vendorEmail}</div>
                    </div>
                    <div className="info-container">
                      <div className="info-label">Timezone</div>
                      <div className="time-zone-value-container">
                        <p className="info-value">{timezoneLabel}</p>
                        <TextButton text="Update" color="purple" onClick={() => setShowTimezoneModal(true)} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="user-container">
                <div className="users-header">
                  <div className="with-badge">
                    <p className="header">Users</p>
                    <div className="pro-badge">Pro</div>
                  </div>
                  <p className="user-count">
                    {totalVendorAdminCount}/{vendorAdminLimit}
                  </p>
                </div>

                <VendorAdmins
                  vendorAdminLimitReached={vendorAdminLimitReached}
                  profilePictureUrl={profilePictureUrl}
                  translate={translate}
                />
              </div>
            </div>
            <div className="divider" />
            <div className="streams-header">
              <div>
                <p>Streams Plans</p>
                <p className="description">Exclusive features for Streams</p>
              </div>
              <TextButton
                color="purple-main"
                text="Full Feature Comparison"
                onClick={() => {
                  setShowFullPlanComparison(true);
                }}
              />
            </div>
            <div className="vendor-card-container">
              <div className="vendor-type-cards">
                <div className={classnames(["vendor-type-card", basic && "selected"])}>
                  <div className="image-container">
                    <img src={VendorBasic} alt="vagon" />
                  </div>
                  <div className="header">
                    <p className="title">Basic</p>
                    <div className="description">
                      <div className="small" />
                      <div className="alt">{basicDescriptionAltText()}</div>
                    </div>
                  </div>
                  <div className="divider" />

                  <div className="benefits-section">
                    <div className="benefit">Basic Features</div>
                    <div className="benefit">No Code Integration</div>
                    <div className="benefit">SDK</div>
                    {basicPlanButton()}
                  </div>
                </div>
                <div className={classnames(["vendor-type-card", pro && "selected"])}>
                  <div className="image-container">
                    <img src={VendorPro} alt="vagon" />
                  </div>
                  <div className="header">
                    <p className="title">Pro</p>
                    <div className="description">
                      + $99.99 <div className="small">/ month</div>
                      <div className="alt">{proDescriptionAltText()}</div>
                    </div>
                  </div>

                  <div className="divider" />
                  <div className="benefits-section">
                    <div className="benefit">All features in Basic Plan</div>
                    <div className="benefit">Application Versioning</div>
                    <div className="benefit">Custom Streams URLs</div>

                    {proPlanButton()}
                  </div>
                </div>
                <div className={classnames(["vendor-type-card", enterprise && "selected"])}>
                  <div className="image-container">
                    <img src={VendorEnterprise} alt="vagon" />
                  </div>
                  <div className="header">
                    <p className="title">Enterprise</p>
                    <div className="description">
                      + $249 <div className="small">/ month</div>
                      <div className="alt">{enterpriseDescriptionAltText()}</div>
                    </div>
                  </div>
                  <div className="divider" />

                  <div className="benefits-section">
                    <div className="benefit">All Features in Pro Plan</div>
                    <div className="benefit">White Label Streams</div>
                    <div className="benefit">Premium Support</div>

                    {enterprisePlanButton()}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {clientSecret && (
          <SecurePaymentModal
            clientSecret={clientSecret}
            onComplete={(intentId) => completeSubscriptionMutation.mutate({ payment_intent: intentId })}
            onFailure={() => {
              setClientSecret(null);
            }}
          />
        )}

        {showUpgradeConfirmationModal && (
          <ConfirmationModal
            closeOnOverlayClick
            pending={createSubscriptionMutation.isLoading}
            closeAction={() => setShowUpgradeConfirmationModal(false)}
            headerText={translate("appStreamingSettings.upgradeSubscription.confirmationModal.header")}
            descriptionText={translate(
              `appStreamingSettings.upgradeSubscription.confirmationModal.description.${pro ? "upgrade" : "new"}`,
              {
                type: capitalize(calculateUpgradePriceMutation.data?.data?.type),
                amount: calculateUpgradePriceMutation.data?.data?.price?.toFixed(2),
                newPrice: 249,
              },
            )}
            confirmText={translate("appStreamingModals.deleteApp.action")}
            confirmAction={() => {
              createSubscriptionMutation.mutate({
                subscription_type: calculateUpgradePriceMutation.data?.data?.type,
              });
            }}
            disableConfirmButton={createSubscriptionMutation.isLoading}
            secondaryText={translate("appStreamingModals.deleteApp.secondaryAction")}
            secondaryAction={() => setShowUpgradeConfirmationModal(false)}
          />
        )}

        {showDowngradeConfirmationModal && (
          <ConfirmationModal
            closeOnOverlayClick
            closeAction={() => setShowDowngradeConfirmationModal(false)}
            headerText={translate("appStreamingSettings.downgradeSubscription.header")}
            descriptionText={translate(
              `appStreamingSettings.downgradeSubscription.${downgradeType}.description.hasAdmins.${vendorAdminsWillBeDeleted()}`,
              {
                type: "Pro",
                amount: 99.99,
                date: moment(subscription?.attributes?.end_at).format("DD MMMM YYYY"),
              },
            )}
            confirmText="Confirm"
            confirmAction={() => {
              downgradeSubscriptionMutation.mutate({
                downgrade_to: downgradeType,
              });
            }}
            secondaryText="Cancel"
            secondaryAction={() => setShowDowngradeConfirmationModal(false)}
          />
        )}
        {createSubscriptionMutation.isError && (
          <PaymentErrorModal
            onClose={() => {
              createSubscriptionMutation.reset();
            }}
            onAction={() => {
              createSubscriptionMutation.reset();
              history.push(routes.appStreamingBilling);
            }}
            errorCode={createSubscriptionMutation.error?.response?.data?.client_code}
          />
        )}
        {showTimezoneModal && <TimezoneModal userTimeZone={userTimeZone} setShowTimezoneModal={setShowTimezoneModal} />}
      </div>
    </DashboardSubScreenLayout>
  );
};

export default AppStreamingSettings;
